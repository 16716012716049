import React from 'react'
import { StarIcon } from '@heroicons/react/outline'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { useForm } from 'react-hook-form'
import { useInView } from 'react-intersection-observer'
import NumberFormat from 'react-number-format'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import { useReportsPerPage } from 'api/reports'
import {
  useAddFavorite,
  useGetFavorite,
  useRemoveFavorite,
} from 'api/favorites'
import useToken from 'store/Auth'
import SystemError from 'components/common/SystemError'
import SessionEndModal from 'components/common/SessionEndModal'
import CustomTooltip from './CustomTooltip'

function Landing(props) {
  const [tab, setTab] = React.useState(0)
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [totals, setTotals] = React.useState()
  const [firstRender, setFirstRender] = React.useState(0)
  const [dataForGraph, setDataForGraph] = React.useState([])
  const [sliceForReports, setSliceForReports] = React.useState(12)
  const [authError, setAuthError] = React.useState(false)
  const [search, setSearch] = React.useState(false)
  const token = useToken((state) => state.token)
  const { ref, inView } = useInView({
    threshold: 1,
  })

  const { register, reset } = useForm({
    defaultValues: {
      searchInput: '',
    },
  })

  React.useEffect(() => {
    setFirstRender(firstRender + 1)
  }, [])

  const dataReports = useReportsPerPage(sliceForReports, searchKeyword, {
    onSuccess: (data) => {
      if (firstRender === 1) {
        setTotals(data?.pages[0]?.totalsWeek?.dailyTotals.at(-1).totals)
        setDataForGraph(data?.pages[0]?.totalsWeek?.dailyTotals)
      }
    },
  })
  const removeFavorite = useRemoveFavorite({
    onError: (err) => {
      setAuthError(true)
    },
  })
  const addFavorite = useAddFavorite({
    onError: (err) => {
      setAuthError(true)
    },
  })
  const getAllFavorites = useGetFavorite()

  const queryClient = useQueryClient()

  const changeApplicationFavoriteStatus = (currStatus, applicationId) => {
    if (currStatus) {
      removeFavorite.mutate(applicationId, {
        onMutate: async (applicationId) => {
          await queryClient.cancelQueries('reports')

          const snapshotOfPreviousReports = queryClient.getQueryData('reports')

          queryClient.setQueryData('reports', (oldReports) =>
            oldReports.map((report) => {
              if (oldReports.applicationTypeId === applicationId) {
                return {
                  ...report,
                  userFavorite: !report.userFavorite,
                }
              }
              return report
            })
          )

          return {
            snapshotOfPreviousReports,
          }
        },
        onSuccess() {
          queryClient.invalidateQueries('reports')
        },
        onError: (error, applicationId, { snapshotOfPreviousReports }) => {
          queryClient.setQueryData('reports', snapshotOfPreviousReports)
        },
      })
    } else {
      addFavorite.mutate(applicationId, {
        onMutate: async (applicationId) => {
          await queryClient.cancelQueries('reports')

          const snapshotOfPreviousReports = queryClient.getQueryData('reports')

          queryClient.setQueryData('reports', (oldReports) =>
            oldReports.map((report) => {
              if (oldReports.applicationTypeId === applicationId) {
                return {
                  ...report,
                  userFavorite: !report.userFavorite,
                }
              }
              return report
            })
          )

          return {
            snapshotOfPreviousReports,
          }
        },
        onSuccess() {
          queryClient.invalidateQueries('reports')
        },
        onError: (error, applicationId, { snapshotOfPreviousReports }) => {
          queryClient.setQueryData('reports', snapshotOfPreviousReports)
        },
      })
    }
  }

  const clearSearch = (e) => {
    setSearchKeyword('')
  }

  React.useEffect(() => {
    if (inView) {
      dataReports.fetchNextPage()
    }
  }, [inView])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dataReports.remove()
    setSearchKeyword('')
    setSliceForReports(12)
    dataReports.fetchNextPage({ pageParam: 1 })
  }, [tab])

  const handleSearchRequest = useDebouncedCallback((e) => {
    setSearchKeyword(e.target.value)
    if (e.target.value) {
      setSliceForReports(20)
    } else {
      dataReports.remove()
      setSliceForReports(12)
      dataReports.fetchNextPage({ pageParam: 1 })
    }
  }, 1000)

  return (
    <div>
      {dataReports?.error?.response?.status >= 500 ? <SystemError /> : null}
      <div
        className={`govgr-admin-width-container govgr-layout-wrapper__full-height p-0${
          dataReports?.error?.response?.status >= 500 ? 'hidden' : ''
        }`}
      >
        {dataReports?.error?.response?.status === 401 ||
        getAllFavorites?.error?.response?.status === 401 ||
        authError ? (
          <SessionEndModal />
        ) : null}
        <div className="govgr-admin-wrapper ">
          <div>
            <div className="govgr-admin-grid-column-12">
              <div
                id="landing-tab"
                className="govgr-button-group flex items-center justify-center text-primary py-5 sticky top-0 bg-white z-10 "
              >
                <button
                  className={`btn px-5 py-2 govgr-!-font-size-24 ${
                    tab === 0 ? 'border-b-2' : ''
                  }`}
                  onClick={() => setTab(0)}
                >
                  Όλες οι αιτήσεις
                </button>
                <button
                  className={`btn px-5 py-2 govgr-!-font-size-24 ${
                    tab === 1 ? 'border-b-2' : ''
                  }`}
                  onClick={() => setTab(1)}
                >
                  Τα αγαπημένα μου
                </button>
              </div>
              {tab === 0 ? (
                <>
                  <h1 className="govgr-body govgr-!-font-size-36  text-center">
                    Σύνολο Ημέρας
                  </h1>
                  <h1 className="govgr-body govgr-!-font-size-48 govgr-!-font-weight-bold text-center text-primary">
                    <NumberFormat
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      displayType={'text'}
                      value={totals ?? 0}
                    />
                  </h1>
                  <div className="grid justify-items-center my-10 px-auto  mx-auto">
                    <div className="xl:w-3/5 xs:w-full">
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          width={800}
                          height={300}
                          data={dataForGraph ?? 0}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="day" />
                          <YAxis
                            tickFormatter={(value) =>
                              new Intl.NumberFormat('el-GR').format(value)
                            }
                          />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="totals"
                            name="Δηλώσεις"
                            className="stroke-primary"
                            activeDot={{ r: 8 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div
                    className={`grid bg-white py-4`}
                    // style={{
                    //   top: document?.getElementById('landing-tab')
                    //     ?.offsetHeight,
                    // }}
                  >
                    <div className="govgr-field ">
                      <div className="govgr-search max-w-auto mx-auto">
                        <form className="w-full">
                          <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-sm">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              className="govgr-input block  pl-12 pr-12"
                              {...(register('searchInput'),
                              {
                                onChange: (e) => {
                                  if (e.target.value !== '') {
                                    setSearch(true)
                                  } else {
                                    setSearch(false)
                                  }
                                  handleSearchRequest(e)
                                },
                              })}
                              type="text"
                              id="search"
                              name="search"
                              placeholder="Αναζήτηση Αίτησης"
                            />
                            {search ? (
                              <div
                                className="absolute inset-y-0 right-5 flex items-center"
                                onClick={clearSearch}
                              >
                                <button type="reset">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`h-6 w-6 text-secondary stroke-secondary`}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    className="govgr-admin-main-content  w-full"
                    style={{ minHeight: '100px' }}
                  >
                    <div className="grid xl:grid-cols-6  md:grid-cols-4 xs:grid-cols-1 gap-8 gap-4 px-8 w-full ">
                      {dataReports?.data
                        ? dataReports?.data.pages.map((page, index) => (
                            <React.Fragment key={index}>
                              {page?.kpisList.length > 0 ? (
                                page.kpisList.map((kpi) => (
                                  <div
                                    key={kpi?.applicationTypeId}
                                    className="govgr-card govgr-card--border govgr-card--border-gray  text-center flex flex-col gap-2  p-2"
                                  >
                                    <div>
                                      <StarIcon
                                        id={kpi?.applicationTypeId}
                                        className={`h-10 w-10 text-secondary ${
                                          kpi?.userFavorite
                                            ? 'fill-secondary hover:fill-white'
                                            : 'fill-white hover:fill-secondary'
                                        }   ml-[-25px] mt-[-25px]  stroke-1`}
                                        onClick={() => {
                                          changeApplicationFavoriteStatus(
                                            kpi?.userFavorite,
                                            kpi?.applicationTypeId
                                          )
                                        }}
                                      />
                                    </div>
                                    <div
                                      role="heading"
                                      className="govgr-card__heading govgr-!-font-size-36 pb-2.5"
                                    >
                                      <NumberFormat
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        displayType={'text'}
                                        value={kpi?.totals}
                                      />
                                    </div>
                                    <div className="govgr-card__text govgr-!-font-size-19 ">
                                      {kpi?.applicationTypeName}
                                    </div>
                                    <div className="mx-auto">
                                      <Link
                                        to={`/statistics/${kpi?.applicationTypeId}`}
                                      >
                                        <button className="govgr-btn govgr-btn-secondary text-sm p-0 mb-0 bg-transparent hover:bg-transparent shadow-none text-[#00b0f0] pb-0 hover:cursor-pointer hover:font-semibold">
                                          Δείτε περισσότερα
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="govgr-!-font-size-20 text-center col-span-4">
                                  Δεν υπάρχει κανένα στοιχείο που να ταιριάζει
                                  στην αναζήτηση σας
                                </div>
                              )}{' '}
                            </React.Fragment>
                          ))
                        : null}
                    </div>

                    <div ref={ref}></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="govgr-admin-main-content  w-full">
                    <div className="grid xl:grid-cols-6  md:grid-cols-4 xs:grid-cols-1 gap-8 gap-4 px-8 w-full">
                      {getAllFavorites.isSuccess
                        ? getAllFavorites?.data.map((kpi) => (
                            <div
                              key={kpi?.applicationTypeId}
                              className="govgr-card govgr-card--border govgr-card--border-gray  text-center flex flex-col gap-2  p-2"
                            >
                              <div>
                                <StarIcon
                                  id={kpi?.applicationTypeId}
                                  className={`h-10 w-10 text-secondary ${
                                    kpi?.userFavorite
                                      ? 'fill-secondary hover:fill-white'
                                      : 'fill-white hover:fill-secondary'
                                  }   ml-[-25px] mt-[-25px]  stroke-1`}
                                  onClick={() => {
                                    changeApplicationFavoriteStatus(
                                      kpi?.userFavorite,
                                      kpi?.applicationTypeId
                                    )
                                  }}
                                />
                              </div>
                              <div
                                role="heading"
                                className="govgr-card__heading govgr-!-font-size-36 pb-2.5"
                              >
                                <NumberFormat
                                  thousandSeparator={'.'}
                                  decimalSeparator={','}
                                  displayType={'text'}
                                  value={kpi?.totals}
                                />
                              </div>
                              <div className="govgr-card__text govgr-!-font-size-19 ">
                                {kpi?.applicationTypeName}
                              </div>
                              <div className="mx-auto">
                                <Link
                                  to={`/statistics/${kpi?.applicationTypeId}`}
                                >
                                  <button className="govgr-btn govgr-btn-secondary text-sm p-0 mb-0 bg-transparent hover:bg-transparent shadow-none text-[#00b0f0] pb-0 hover:cursor-pointer hover:font-semibold">
                                    Δείτε περισσότερα
                                  </button>
                                </Link>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {dataReports.isLoading ? (
        <div className="govgr-admin-width-container govgr-layout-wrapper__full-height">
          <div className="govgr-admin-wrapper ">
            <div className="govgr-admin-width-container">
              <div>
                <div className="govgr-admin-grid-column-12">
                  <div className="govgr-loader__container col-span-5">
                    <svg
                      className="govgr-loader"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 200"
                    >
                      <path
                        className="govgr-loader__circle govgr-loader__circle--1"
                        d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                      ></path>
                      <path
                        className="govgr-loader__circle govgr-loader__circle--2"
                        d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Landing
