import React from 'react'
import { Link, useParams } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { addDays } from 'date-fns'
import { useForm, Controller } from 'react-hook-form'
import { useCompareSearch, useDatesReport } from 'api/reports'
import { format, subDays, startOfYear, getMonth } from 'date-fns'
import NumberFormat from 'react-number-format'
import { useDebouncedCallback } from 'use-debounce'
import Graph from './Graph'
import el from 'date-fns/locale/el'
import SystemError from 'components/common/SystemError'
import SessionEndModal from 'components/common/SessionEndModal'
registerLocale('el', el)
const months = [
  { number: '01', label: 'Ιανουάριος' },
  { number: '02', label: 'Φεβρουάριος' },
  { number: '03', label: 'Μάρτιος' },
  { number: '04', label: 'Απρίλιος' },
  { number: '05', label: 'Μάιος' },
  { number: '06', label: 'Ιούνιος' },
  { number: '07', label: 'Ιούλιος' },
  { number: '08', label: 'Αύγουστος' },
  { number: '09', label: 'Σεπτέμβριος' },
  { number: '10', label: 'Οκτώβριος' },
  { number: '11', label: 'Νοέμβριος' },
  { number: '12', label: 'Δεκέμβριος' },
]

function Statistics(props) {
  const [statsTab, setStatsTab] = React.useState(1)
  const [startDate, setStartDate] = React.useState(null)
  const [showSearchPanel, setShowSearchPanel] = React.useState(false)
  const [endDate, setEndDate] = React.useState(new Date())
  const [mobileMenu, setMobileMenu] = React.useState(false)
  const [kpiToCompared, setKpiToCompared] = React.useState<any>()

  const [currDate, setCurrDate] = React.useState(
    format(new Date(), 'yyyy-MM-dd')
  )

  const dataByDates = useDatesReport()
  const toCompare = useDatesReport()
  const { applicationId } = useParams()
  const compareSearch = useCompareSearch()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    let startingDate = ''
    if (statsTab === 1) {
      startingDate = format(subDays(new Date(), 7), 'yyyy-MM-dd')
    } else if (statsTab === 2) {
      startingDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
    } else if (statsTab === 3) {
      startingDate = format(subDays(new Date(), 365), 'yyyy-MM-dd')
    } else if (statsTab === 4) {
      startingDate = format(startOfYear(new Date()), 'yyyy-MM-dd')
    }

    if (statsTab !== 5) {
      dataByDates.mutate(
        {
          applicationTypeId: applicationId,
          dateFrom: startingDate,
          dateTo: currDate,
        },
        { onSuccess: (data) => foundTheMonths(data?.dailyTotals) }
      )
    }

    if (statsTab === 5) {
      dataByDates.mutate({
        applicationTypeId: applicationId,
        dateFrom: '',
        dateTo: '',
      })
    }
  }, [statsTab])

  const foundTheMonths = (data) => {
    data.map((item) => {
      const month = item?.day.split('/')
      const monthName = months.find((element) => element.number === month[1])
      item.month = monthName?.label
    })
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      startDate: '',
      endDate: new Date(),
    },
  })

  const { register, resetField, watch, setValue } = useForm({
    defaultValues: {
      applicationTypeName: '',
    },
  })

  const watchSearch = watch('applicationTypeName')

  const onSubmit = async (data) => {
    const payload = {
      applicationTypeId: applicationId,
      dateFrom: format(data.startDate, 'yyyy-MM-dd'),
      dateTo: format(data.endDate, 'yyyy-MM-dd'),
    }
    dataByDates.mutate(payload, {
      onSuccess: (data) => foundTheMonths(data?.dailyTotals),
    })

    if (watchSearch) {
      toCompare.mutate({
        applicationTypeId: kpiToCompared?.applicationTypeId,
        dateFrom: format(data.startDate, 'yyyy-MM-dd'),
        dateTo: format(data.endDate, 'yyyy-MM-dd'),
      })
    }
    window.scrollTo(0, 0)
  }

  const handleSearchRequest = useDebouncedCallback((e) => {
    setShowSearchPanel(true)
    compareSearch.mutate({ applicationTypeName: e.target.value })
  }, 1000)

  const clearSearch = (e) => {
    resetField('applicationTypeName')
    toCompare.reset()
  }

  React.useEffect(() => {
    let startingDate = ''
    setValue('applicationTypeName', kpiToCompared?.applicationTypeName)
    setShowSearchPanel(false)
    if (statsTab === 1) {
      startingDate = format(subDays(new Date(), 7), 'yyyy-MM-dd')
    } else if (statsTab === 2) {
      startingDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
    } else if (statsTab === 3) {
      startingDate = format(subDays(new Date(), 365), 'yyyy-MM-dd')
    } else if (statsTab === 4) {
      startingDate = format(startOfYear(new Date()), 'yyyy-MM-dd')
    }

    if (statsTab !== 5) {
      toCompare.mutate({
        applicationTypeId: kpiToCompared?.applicationTypeId,
        dateFrom: startingDate,
        dateTo: currDate,
      })
    }

    if (statsTab === 5) {
      toCompare.mutate({
        applicationTypeId: kpiToCompared?.applicationTypeId,
        dateFrom: getValues('startDate'),
        dateTo: getValues('endDate'),
      })
    }
  }, [kpiToCompared, statsTab])

  return (
    <>
      {dataByDates.isLoading ? (
        <div className="govgr-admin-width-container govgr-layout-wrapper__full-height">
          <div className="govgr-admin-wrapper ">
            <div className="govgr-admin-width-container">
              <div>
                <div className="govgr-admin-grid-column-12">
                  <div className="govgr-loader__container col-span-5">
                    <svg
                      className="govgr-loader"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 200"
                    >
                      <path
                        className="govgr-loader__circle govgr-loader__circle--1"
                        d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                      ></path>
                      <path
                        className="govgr-loader__circle govgr-loader__circle--2"
                        d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {dataByDates?.error?.response?.status >= 500 ? (
        <SystemError />
      ) : dataByDates?.error?.response?.status === 401 ||
        compareSearch?.error?.response?.status === 401 ||
        toCompare?.error?.response?.status === 401 ? (
        <SessionEndModal />
      ) : null}
      {/* {dataByDates.isSuccess ? ( */}
      <div
        className={`govgr-width-container ${
          dataByDates?.error?.response?.status >= 500 || dataByDates.isLoading
            ? 'hidden'
            : ''
        }`}
      >
        <div className="pt-8">
          <Link to="/applications" className="govgr-back-link">
            <svg
              className="govgr-caret--left govgr-svg-icon"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M18,22V2L6,12L18,22z"></path>
            </svg>
            Επιστροφή
          </Link>
        </div>

        <div className={`grid grid-cols-2 flex items-center md:hidden`}>
          <div className="my-10">
            <p className="govgr-!-font-size-36 govgr-!-font-weight-bold xs:text-center md:text-left">
              {dataByDates?.data?.applicationTypeName}
            </p>
          </div>
          <div className="my-10 justify-self-end">
            <button onClick={() => setMobileMenu(!mobileMenu)}>
              {mobileMenu ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-8 h-8 stroke-primary hover:stroke-secondary hover:cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m-15 0l15 15"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  className="w-8 h-8 stroke-primary hover:stroke-secondary hover:cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-4 flex my-10">
          <div className="xs:col-span-4 md:col-span-3 xs:order-2 md:order-1">
            <div
              className={`govgr-button-group ${
                mobileMenu ? 'xs:flex' : 'xs:hidden'
              }  md:flex`}
            >
              <button
                className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white xs:w-full md:w-auto ${
                  statsTab === 1 ? 'bg-primary text-white' : 'bg-white'
                }`}
                onClick={() => setStatsTab(1)}
              >
                Εβδομάδα
              </button>
              <button
                className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white xs:w-full md:w-auto ${
                  statsTab === 2 ? 'bg-primary text-white' : 'bg-white'
                }`}
                onClick={() => setStatsTab(2)}
              >
                Μήνας
              </button>
              <button
                className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white xs:w-full md:w-auto ${
                  statsTab === 3 ? 'bg-primary text-white' : 'bg-white'
                }`}
                onClick={() => setStatsTab(3)}
              >
                Έτος
              </button>
              <button
                className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white xs:w-full md:w-auto ${
                  statsTab === 4 ? 'bg-primary text-white' : 'bg-white'
                }`}
                onClick={() => setStatsTab(4)}
              >
                Τρέχων έτος
              </button>
              <button
                className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white  xs:w-full md:w-auto ${
                  statsTab === 5 ? 'bg-primary text-white' : 'bg-white'
                }`}
                onClick={() => setStatsTab(5)}
              >
                Συγκεκριμένες ημερομηνίες
              </button>
            </div>
            {statsTab === 5 ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={`grid grid-cols-12 ${
                    mobileMenu ? 'xs:flex' : 'xs:hidden'
                  } md:flex flex-row gap-4 text-center items-center w-auto`}
                >
                  <div className="xs:col-span-5 sm:col-span-4">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'Το πεδίο είναι υποχρεωτικό' }}
                      render={({ field: { onChange } }) => (
                        <>
                          <DatePicker
                            locale="el"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => {
                              onChange(date)
                              setStartDate(date)
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={addDays(new Date(), 0)}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Από"
                            className={`border-2 py-3 px-4 w-full ${
                              errors.startDate ? 'govgr-field__error' : ''
                            } `}
                            onKeyDown={(e) => {
                              e.preventDefault()
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div className="xs:col-span-2 sm:col-span-1">
                    <p className="govgr-!-font-size-20 govgr-!-font-weight-bold ">
                      -
                    </p>
                  </div>
                  <div className="xs:col-span-5 sm:col-span-4">
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <DatePicker
                          locale="el"
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          onChange={(date) => {
                            onChange(date)
                            setEndDate(date)
                          }}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={addDays(new Date(), 0)}
                          minDate={startDate}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className={`border-2 py-3 px-4 w-full ${
                            errors.startDate ? 'govgr-field__error' : ''
                          } `}
                          placeholderText="Έως"
                        />
                      )}
                    />
                  </div>

                  <div className="xs:col-span-12 sm:col-span-2">
                    <button
                      className={`py-3 px-4 btn border-2 border-primary hover:bg-primary text-primary hover:text-white 
                     bg-primary text-white xs:w-full sm:w-auto`}
                      type="submit"
                    >
                      Αναζήτηση
                    </button>
                  </div>
                </div>
              </form>
            ) : null}
            <div className="my-10">
              <p className="govgr-!-font-size-36 govgr-!-font-weight-bold xs:text-center md:text-left">
                {dataByDates?.data?.applicationTypeName}
              </p>
            </div>
            <form
              className="w-full"
              onChange={(e) => {
                handleSearchRequest(e)
              }}
            >
              <div id="search" className="relative w-full">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  id="kpiSearch"
                  className="govgr-input block  pl-12 pr-12"
                  {...register('applicationTypeName')}
                  placeholder="Αναζήτηση Αίτησης"
                />
                {watchSearch ? (
                  <div
                    className="absolute inset-y-0 right-5 flex items-center"
                    onClick={clearSearch}
                  >
                    <button type="reset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`h-6 w-6 text-secondary stroke-secondary`}
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}
              </div>
            </form>
            {watchSearch && showSearchPanel ? (
              <div
                className={`origin-top-right  mt-2 absolute rounded-md shadow-lg bg-white ring-1 ring-black z-10 ring-opacity-5 focus:outline-none`}
                id="searchDropdown"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                style={{
                  width: document?.getElementById('search')?.offsetWidth,
                }}
              >
                <div className="py-1" role="none">
                  {compareSearch?.data?.kpisList.map((item, index) => (
                    <React.Fragment key={index}>
                      <button
                        key={item?.applicationTypeId}
                        className="text-gray-700 block px-4 py-2 text-sm border-b-[1px] border-slate-200 w-full text-left"
                        style={{ borderColor: '#e0e0e0' }}
                        role="menuitem"
                        id={`menu-item-${index}`}
                        onClick={() => setKpiToCompared(item)}
                      >
                        {item?.applicationTypeName}
                      </button>
                    </React.Fragment>
                  ))}
                  {compareSearch?.data?.kpisList.length > 30 ? (
                    <p className="text-gray-700 block px-4 py-2 text-sm text-center font-bold">
                      Παρακαλώ προσθέστε και άλλους χαρακτήρες για να βρείτε το
                      KPI που ψάχνετε
                    </p>
                  ) : compareSearch?.data?.kpisList.length === 0 ? (
                    <p className="text-gray-700 block px-4 py-2 text-sm text-center font-bold">
                      Δεν βρέθηκαν αποτελέσματα
                    </p>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className="xs:col-span-4 md:col-span-1 xs:order-1 md:order-2 my-6">
            <div>
              <p className="text-center govgr-!-font-size-24 py-2">
                Μέσος Όρος
              </p>
            </div>
            <div>
              <p className="text-center govgr-!-font-size-30 py-2">
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                  value={dataByDates?.data?.rangeAvegareRequestsPerDay ?? 0}
                />
              </p>
            </div>
            <div>
              <p className="text-center govgr-!-font-size-24 py-2">Σύνολο</p>
            </div>
            <div>
              <p className="text-center govgr-!-font-size-30 py-2">
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                  value={dataByDates?.data?.rangeTotalRequests ?? 0}
                />
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="govgr-!-font-size-30 my-15">Αθροιστικό</p>
          <div>
            {dataByDates?.data ? (
              <Graph
                firstKpi={dataByDates?.data ?? 0}
                secondKpi={toCompare?.data ?? 0}
                tab={statsTab}
                type="sumOfDays"
              />
            ) : null}
          </div>
        </div>
        <div>
          <p className="govgr-!-font-size-30 my-15">Αιτήσεις ανά ημέρα</p>
          {dataByDates?.data ? (
            <Graph
              firstKpi={dataByDates?.data}
              secondKpi={toCompare?.data}
              tab={statsTab}
              type="totals"
            />
          ) : null}
        </div>
      </div>
      {/* // ) : null} */}
    </>
  )
}

export default Statistics
