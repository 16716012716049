import * as React from 'react'
import { Link } from 'react-router-dom'

function NotAccessPage() {
  return (
    <div className="govgr-width-container flex items-center justify-center">
      <div className="govgr-main-wrapper">
        <main className="govgr-grid-column-two-thirds">
          <h1 className="govgr-heading-xl">
            Δεν έχετε πρόσβαση σε αυτή την σελίδα
          </h1>
          <p className="govgr-body">
            Για να αποκτήσετε πρόσβαση στην συγκεκριμένη σελίδα θα πρέπει να
            συνδεθείτε
          </p>
          <Link to="/login">
            <button className="govgr-btn govgr-btn-primary govgr-btn-cta">
              Σύνδεση
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right"
                focusable="false"
                aria-hidden="true"
              >
                {' '}
                <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" />{' '}
              </svg>
            </button>
          </Link>
        </main>
      </div>
    </div>
  )
}

export default NotAccessPage
