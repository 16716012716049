import axios from 'axios'
import { useMutation, useInfiniteQuery } from 'react-query'
import api from 'api'
import AuthService from 'services/AuthService'

// Search in zoom in page
export const createCompareSearch = async (payload) => {
  const { data } = await api({
    method: 'post',
    url: 'app/govgr/analytics/kpi/dashboard/search',
    data: {
      ...payload,
      pageSize: 30,
    },
  })
  return data
}

export const useCompareSearch = (options = {}) => {
  return useMutation(createCompareSearch, { ...options })
}

// Reports per page
export const createReportsPerPage = async ({ queryKey, pageParam = 0 }) => {
  const [, keyword] = queryKey
  const { data } = await api({
    method: 'post',
    url: 'app/govgr/analytics/kpi/dashboard/search',
    data: { applicationTypeName: keyword, pageNumber: pageParam },
  })
  return data
}

export const useReportsPerPage = (slice, keyword, options = {}) => {
  return useInfiniteQuery(['report', keyword], createReportsPerPage, {
    cacheTime: 0,
    ...options,
    getNextPageParam: (lastPage, pages) => {
      if (pages.length < Math.ceil(lastPage.numberOfApplicationTypes / slice)) {
        return pages.length
      } else {
        return undefined
      }
      return pages
    },
  })
}

// Search for reports
export const createSearchReport = async (payload) => {
  const { data } = await api({
    method: 'post',
    url: 'app/govgr/analytics/kpi/dashboard/search',
    data: { ...payload },
  })
  return data
}

export const useSearchReport = (options = {}) => {
  return useMutation(createSearchReport, {
    ...options,
  })
}

// Reports by day
export const createDatesReport = async (payload) => {
  const { data } = await api({
    method: 'post',
    url: 'app/govgr/analytics/kpi/details',
    data: {
      ...payload,
    },
  })
  return data
}

export const useDatesReport = (options = {}) => {
  return useMutation(createDatesReport, { ...options })
}
