import { useResetPassword } from 'api/auth'
import React from 'react'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

function ResetPassword(props) {
  const [errorMessage, setErrorMessage] = React.useState('')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const createGoogleToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha()
  }, [executeRecaptcha])

  const resetPassword = useResetPassword({
    onError: (error) => {
      if (error.response.status >= 500) {
        setErrorMessage(
          'Αυτή τη στιγμή η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε αργότερα.'
        )
      }
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { username: '' },
  })

  const onSubmit = async (data) => {
    const token = await createGoogleToken()
    resetPassword.mutate(`${data?.username}?token=${token}`)
  }

  return (
    <div className="govgr-width-container flex items-center justify-cente">
      <div className="govgr-main-wrapper">
        <main className="govgr-grid-column-two-thirds">
          <h1 className="govgr-heading-xl">Επαναφορά κωδικού πρόσβασης</h1>
          <p className="govgr-body">
            Για να επαναφέρετε τον κωδικό σας,πρέπει να δώσετε το Όνομα Χρήστη
            και να πατήσετε το κουμπί "Επαναφορά". Εφόσον το Όνομα Χρήστη που
            δώσατε βρεθεί στο σύστημα, ένα email θα σταλεί στο email που έχετε
            δηλώσει
          </p>
          {resetPassword.isSuccess ? (
            <>
              {resetPassword.data?.successful ? (
                <div
                  className="govgr-notification-banner govgr-notification-banner--success"
                  role="region"
                  aria-labelledby="govgr-notification-banner-title"
                  data-module="govgr-notification-banner"
                >
                  <div className="govgr-notification-banner__header">
                    <h2
                      className="govgr-notification-banner__title"
                      id="govgr-notification-banner-title"
                    >
                      Επιτυχία
                    </h2>
                  </div>
                  <div className="govgr-notification-banner__content">
                    <p className="gr-body">
                      Σας έχουμε στείλει ένα email, στη διεύθυνση που είναι
                      συνδεδεμένη με αυτό το username
                    </p>
                  </div>
                </div>
              ) : (
                <div className="govgr-error-summary">
                  <h3 className="govgr-heading-s">Υπήρξε κάποιο πρόβλημα</h3>
                  <a className="govgr-link" href="#">
                    {' '}
                    Δεν βρέθηκε λογαριασμός με το συγκεκριμένο όνομα χρήστη
                  </a>
                </div>
              )}
            </>
          ) : null}
          {errorMessage ? (
            <div className="govgr-error-summary">
              <h3 className="govgr-heading-s">Υπήρξε κάποιο πρόβλημα</h3>
              <a className="govgr-link" href="#">
                {errorMessage}
              </a>
            </div>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`govgr-field ${
                errors.username ? 'govgr-field__error' : ''
              }`}
            >
              <label className="govgr-label">
                <span>Όνομα Χρήστη</span>
                {errors.username?.type === 'pattern' ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span> Το
                    όνομα χρήστη πρέπει μόνο να περιλαμβάνει τα γράμματα a μέχρι
                    z, παπάκι και τελεία
                  </p>
                ) : errors.username ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span>{' '}
                    Εισάγετε όνομα χρήστη
                  </p>
                ) : null}
                <input
                  className={`govgr-input ${
                    errors.username ? 'govgr-error-input' : ''
                  }`}
                  type="text"
                  {...register('username', {
                    required: true,
                  })}
                />
              </label>
            </div>
            <div className="govgr-button-group">
              <button
                className={`govgr-btn govgr-btn-primary govgr-btn-cta  ${
                  !isValid ? 'govgr-btn-disabled' : ''
                }`}
                type="submit"
              >
                Επαναφορά
                <svg
                  viewBox="0 0 24 24"
                  className="govgr-arrow--right"
                  focusable="false"
                  aria-hidden="true"
                >
                  {' '}
                  <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" />{' '}
                </svg>
              </button>
              <Link to="/login" className="govgr-link">
                Ακύρωση
              </Link>
            </div>
          </form>
        </main>
      </div>
    </div>
  )
}

export default ResetPassword
