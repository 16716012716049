import React from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import CustomTooltip from './CustomTooltip'

interface Props {
  firstKpi: any
  tab: number
  type: string
  secondKpi: any
}

function Graph({ firstKpi, tab, type, secondKpi }: Props) {
  const [interval, setInterval] = React.useState<
    number | 'preserveStart' | 'preserveEnd' | 'preserveStartEnd'
  >(0)
  const [XAxisData, setXAxisData] = React.useState<string>('')

  React.useEffect(() => {
    if (tab === 1) {
      setInterval(0)
      setXAxisData('day')
    } else if (tab === 2) {
      setInterval(1)
      setXAxisData('day')
    } else if (tab === 3) {
      setInterval(90)
      setXAxisData('month')
    } else if (tab === 4 || tab === 5) {
      if (
        firstKpi?.dailyTotals.length >= 1 &&
        firstKpi?.dailyTotals.length <= 30
      ) {
        if (firstKpi?.dailyTotals.length >= 20) {
          setInterval(1)
        }
        setXAxisData('day')
      } else if (
        firstKpi?.dailyTotals.length >= 31 &&
        firstKpi?.dailyTotals.length <= 120
      ) {
        setInterval(Math.floor(firstKpi?.dailyTotals.length / 4))
        setXAxisData('day')
      } else if (firstKpi?.dailyTotals.length > 120) {
        setInterval(Math.floor(firstKpi?.dailyTotals.length / 4))
        setXAxisData('month')
      }
    }
  }, [])

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis xAxisId={1} dataKey={`${XAxisData}`} interval={interval} />
        <XAxis xAxisId={2} hide={true} />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('el-GR').format(value)
          }
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        <Line
          xAxisId={1}
          type="monotone"
          data={firstKpi?.dailyTotals}
          name={`${firstKpi?.applicationTypeName}`}
          dataKey={`${type}`}
          className="stroke-primary"
        />
        {secondKpi ? (
          <Line
            xAxisId={2}
            type="monotone"
            data={secondKpi?.dailyTotals}
            name={`${secondKpi?.applicationTypeName}`}
            dataKey={`${type}`}
            stroke="#ff6500"
          />
        ) : null}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Graph
