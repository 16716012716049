import { useLougout } from 'api/auth'
import React from 'react'
import useToken from 'store/Auth'

function SessionEndModal(props) {
  const logout = useLougout()
  const setRefreshToken = useToken((state) => state?.setRefreshToken)
  const setAccessToken = useToken((state) => state?.setAccessToken)

  const logoutReq = () => {
    logout.mutate()
    setRefreshToken('')
    setAccessToken('')
    window.location.replace('/')
  }

  const refreshTokenReq = () => {
    window.location.reload()
  }

  return (
    <div className="govgr-modal govgr-modal__open">
      <div className="govgr-modal__body overflow-y-hidden">
        <div className="govgr-modal__content">
          <div className="govgr-modal__text">
            Έχετε παραμείνει αδρανής για μεγάλο διάστημα, Θέλετε να παραμείνετε
            συνδεδεμένοι;
          </div>
          <div className="govgr-modal__action">
            <button
              className="govgr-btn-primary govgr-btn"
              onClick={refreshTokenReq}
            >
              Ναι
            </button>
            <button className="govgr-link" onClick={logoutReq}>
              Όχι
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionEndModal
