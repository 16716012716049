/* eslint-disable no-undef */
import axios from 'axios'
import { QueryClient, QueryCache } from 'react-query'
import AuthService from 'services/AuthService'
import _kc from 'keycloak'
import useToken from 'store/Auth'

const baseApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
})

function getAccessToken() {
  // return useAuthStore.getState().accessToken
  return AuthService.getToken()
}

baseApi.interceptors.request.use(async (request) => {
  if (AuthService.isLoggedIn()) {
    const accessToken = getAccessToken()
    request.headers.Authorization = `Bearer ${accessToken}`
    _kc
      .updateToken(5)
      .success((refreshed) => {
        if (refreshed) {
          const getNewAccessToken = AuthService.getToken()
          const getNewRefreshToken = AuthService.getRefreshToken()
          useToken.getState().setAccessToken(getNewAccessToken)
          useToken.getState().setRefreshToken(getNewRefreshToken)
        }
      })
      .error(function () {
        console.log('Failed to refresh the token, or the session has expired')
      })
  }
  return request
})

baseApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error
    if (status === 401) {
    }
    return Promise.reject(error)
  }
)

export const queryCache = new QueryCache({})
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export default baseApi
