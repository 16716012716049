import axios from 'axios'
import { useMutation, useInfiniteQuery, useQuery } from 'react-query'
import api from 'api'

// Get favorites
export const createGetFavorite = async ({ queryKey }) => {
  const { data } = await api({
    method: 'get',
    url: 'app/govgr/analytics/user-favorites/get-all',
  })
  return data
}

export const useGetFavorite = (options = {}) => {
  return useQuery(['favorites'], createGetFavorite, { ...options })
}

// Remove favorites
export const createRemoveFavorite = async (id, payload) => {
  const { data } = await api({
    method: 'delete',
    url: `app/govgr/analytics/user-favorites/delete/${id}`,
  })
  return data
}
export const useRemoveFavorite = (options = {}) => {
  return useMutation(createRemoveFavorite, { ...options })
}

// Add favorites
export const createAddFavorite = async (id, payload) => {
  const { data } = await api({
    method: 'post',
    url: `app/govgr/analytics/user-favorites/add/${id}`,
    data: { ...payload },
  })
  return data
}

export const useAddFavorite = (options = {}) => {
  return useMutation(createAddFavorite, { ...options })
}
