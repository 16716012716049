import React from 'react'

function SystemError(props) {
  return (
    <div className="govgr-admin-width-container govgr-layout-wrapper__full-height">
      <div className="govgr-admin-wrapper ">
        <div className="govgr-admin-width-container">
          <div className="govgr-admin-grid-column-12">
            <div className="govgr-error-summary">
              <h3 className="govgr-heading-s">
                Αυτή τη στιγμή η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ
                δοκιμάστε αργότερα.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SystemError
