import * as React from 'react'
import NegativeLogo from '../../assets/svg/logo_govgr_neg.svg'
import GovFooterLogo from '../../assets/svg/government_logo2.svg'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useLougout } from 'api/auth'
import useToken from 'store/Auth'
import AuthService from 'services/AuthService'

function BasicLayout(props) {
  const location = useLocation()
  const currentYear = new Date().getFullYear()
  const logout = useLougout()
  const setRefreshToken = useToken((state) => state?.setRefreshToken)
  const setAccessToken = useToken((state) => state?.setAccessToken)
  const [logoutConfirmation, setLogoutConfirmation] =
    React.useState<Boolean>(false)
  const navigate = useNavigate()

  const logoutReq = () => {
    logout.mutate()
    setRefreshToken('')
    setAccessToken('')
    window.location.replace('/')
  }

  return (
    <div className="govgr-layout-wrapper__full-height govgr-layout-wrapper">
      <div className="govgr-top">
        <header
          role="banner"
          data-module="govgr-header"
          className="govgr-header"
        >
          <div className="govgr-header__container ">
            <div className="govgr-header__content flex justify-between w-full">
              <a className="govgr-header-link">
                <img src={NegativeLogo} className="govgr-header-logo" />
              </a>
              {AuthService.isLoggedIn() ? (
                <button
                  className="govgr-btn govgr-btn-secondary m-0"
                  onClick={() => setLogoutConfirmation(true)}
                >
                  Αποσύνδεση
                </button>
              ) : null}
            </div>
          </div>
        </header>
        <div>
          <div
            className={`govgr-modal ${
              logoutConfirmation ? 'govgr-modal__open' : ''
            }`}
          >
            <div className="govgr-modal__body overflow-y-hidden">
              <div className="govgr-modal__content">
                <div className="govgr-modal__text">
                  Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;
                </div>
                <div className="govgr-modal__action">
                  <button
                    className="govgr-btn-primary govgr-btn"
                    onClick={logoutReq}
                  >
                    Ναι
                  </button>
                  <button
                    className="govgr-link"
                    onClick={() => setLogoutConfirmation(false)}
                  >
                    Όχι
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />

      <div className="govgr-bottom">
        <footer className="govgr-footer">
          <div className="govgr-width-container">
            <div className="govgr-footer__meta">
              <div className="govgr-footer__meta-item--grow govgr-footer__meta-item">
                <div className="govgr-footer__content">
                  <p className="govgr-footer__licence-description">
                    © Copyright {currentYear} - Powered by{' '}
                    <a
                      href="https://grnet.gr/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="govgr-link"
                    >
                      {' '}
                      GRNET{' '}
                      <span className="govgr-visually-hidden">
                        (opens in a new tab){' '}
                      </span>
                    </a>{' '}
                    for the{' '}
                    <a
                      href="https://mindigital.gr/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="govgr-link"
                    >
                      {' '}
                      Ministry of Digital Governance{' '}
                      <span className="govgr-visually-hidden">
                        (opens in a new tab){' '}
                      </span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="govgr-footer__meta-item">
                <img
                  className="govgr-footer__government-logo"
                  src={GovFooterLogo}
                />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default BasicLayout
