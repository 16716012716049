import { useSetNewPassword } from 'api/auth'
import React from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

interface FormValues {
  newPassword: string
  confirmNewPassword: string
}
function SetPassword(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [errorMessage, setErrorMessage] = React.useState('')

  const setPassword = useSetNewPassword()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { newPassword: '', confirmNewPassword: '' },
  })

  const password = React.useRef({})
  password.current = watch('newPassword', '')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const createGoogleToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha()
  }, [executeRecaptcha])

  const onSubmit = async (data: FormValues) => {
    const token = await createGoogleToken()
    setPassword.mutate(
      {
        token: searchParams.get('token'),
        password: data?.newPassword,
        confirmationPassword: data?.confirmNewPassword,
        recaptchaToken: token,
      },
      {
        onSuccess: (data) => {
          navigate('/login')
        },
        onError: (error) => {
          if (error.response.status >= 500) {
            setErrorMessage(
              'Αυτή τη στιγμή η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε αργότερα.'
            )
          } else {
            setErrorMessage('Παρακαλώ δοκιμάστε αργότερα.')
          }
        },
      }
    )
  }

  return (
    <div className="govgr-width-container flex items-center justify-cente">
      <div className="govgr-main-wrapper">
        <main className="govgr-grid-column-two-thirds">
          <h1 className="govgr-heading-xl">Επαναφορά κωδικού πρόσβασης</h1>
          {errorMessage ? (
            <div className="govgr-error-summary">
              <h3 className="govgr-heading-s">Υπήρξε κάποιο πρόβλημα</h3>
              <a className="govgr-link" href="#">
                {errorMessage}
              </a>
            </div>
          ) : null}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`govgr-field ${
                errors.newPassword ? 'govgr-field__error' : ''
              }`}
            >
              <label className="govgr-label">
                <span>Νέος κωδικός πρόσβασης</span>
                {errors.newPassword ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span>{' '}
                    {errors.newPassword.message}
                  </p>
                ) : null}
                <input
                  className={`govgr-input ${
                    errors.newPassword ? 'govgr-error-input' : ''
                  }`}
                  type="password"
                  {...register('newPassword', {
                    required: 'Εισάγετε τον νέο κωδικό πρόσβασης',
                  })}
                />
              </label>
            </div>
            <div
              className={`govgr-field ${
                errors.confirmNewPassword ? 'govgr-field__error' : ''
              }`}
            >
              <label className="govgr-label">
                <span>Επιβεβαίωση κωδικού πρόσβασης</span>
                {errors.confirmNewPassword ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span>{' '}
                    {errors.confirmNewPassword.message}
                  </p>
                ) : null}
                <input
                  className={`govgr-input ${
                    errors.confirmNewPassword ? 'govgr-error-input' : ''
                  }`}
                  type="password"
                  {...register('confirmNewPassword', {
                    required: 'Εισάγετε τον νέο κωδικό πρόσβασης',
                    validate: (value) =>
                      value === password.current || 'Οι κωδικοί δεν ταιριάζουν',
                  })}
                />
              </label>
            </div>
            <div className="govgr-button-group">
              <button
                className={`govgr-btn govgr-btn-primary govgr-btn-cta  ${
                  !isValid ? 'govgr-btn-disabled' : ''
                }`}
                type="submit"
              >
                Επαναφορά
                <svg
                  viewBox="0 0 24 24"
                  className="govgr-arrow--right"
                  focusable="false"
                  aria-hidden="true"
                >
                  {' '}
                  <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" />{' '}
                </svg>
              </button>
            </div>
          </form>
        </main>
      </div>
    </div>
  )
}

export default SetPassword
