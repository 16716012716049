/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _kc from 'keycloak'
import useToken from 'store/Auth'

const refreshToken = useToken.getState().refreshToken
const accessToken = useToken.getState().accessToken

const initKeycloak = () => {
  return _kc.init({
    // onLoad: 'check-sso',
    // silentCheckSsoRedirectUri:
    //   window.location.origin + '/silent-check-sso.html',
    checkLoginIframe: false,
    responseMode: 'query',
    // enableLogging: process.env.REACT_APP_ENV === 'production' ? false : true,
    token: accessToken ?? undefined,
    refreshToken: refreshToken ?? undefined,
  })
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token

const getRefreshToken = () => _kc.refreshToken

const isLoggedIn = () => _kc.authenticated

// const updateToken = (successCallback, errorCallback) =>
//   _kc
//     .updateToken(30)
//     .then((refreshed) => {
//       // if (refreshed) {
//       if (!!_kc.token && _kc.refreshToken) {
//         console.log(refreshed)
//       }
//       // }
//       return successCallback(_kc.token)
//       // if (refreshed) {
//       // const newToken = _kc.getToken()
//       // const newRefreshToken = _kc.getRefreshToken()
//       // if (!!newToken && !!newRefreshToken) {
//       //   TokenService.setAccessToken(newToken)
//       //   TokenService.setRefreshToken(newRefreshToken)
//       //   setAccessTokenInStore(newToken)
//       //   setRefreshToken(newRefreshToken)
//       // }
//       // // }
//       // return successCallback(newToken)
//     })
//     .catch(() => {
//       if (errorCallback) {
//         errorCallback()
//       }
//       // resetAuthStore()
//     })

const clearAuthState = () => _kc.clearToken()

const AuthService = {
  initKeycloak,
  clearAuthState,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getRefreshToken,
}

export default AuthService
