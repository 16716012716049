import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useLogin } from 'api/auth'
import { Link, useNavigate } from 'react-router-dom'
import useToken from 'store/Auth'
import useAccessToken from 'store/Auth'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

function Login() {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = React.useState('')
  const setRefreshToken = useToken((state) => state?.setRefreshToken)
  const setAccessToken = useToken((state) => state?.setAccessToken)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const createGoogleToken = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha()
  }, [executeRecaptcha])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { username: '', password: '' },
  })

  const login = useLogin({
    onSuccess: (data) => {
      navigate('/applications')
      setRefreshToken(data?.refreshToken)
      setAccessToken(data?.accessToken)
      window.location.reload()
    },
    onError: (error) => {
      if (error.response.status >= 500) {
        setErrorMessage(
          'Αυτή τη στιγμή η υπηρεσία δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε αργότερα.'
        )
      } else {
        setErrorMessage('Το όνομα ή/και ο κωδικός χρήστη δεν είναι σωστός')
      }
    },
  })

  const onSubmit = async (data) => {
    const token = await createGoogleToken()
    const payload = {
      token: token,
      ...data,
    }
    console.log(payload)
    login.mutate(payload)
  }

  return (
    <div className="govgr-width-container flex items-center justify-cente">
      <div className="govgr-main-wrapper">
        <main className="govgr-grid-column-two-thirds">
          <h1 className="govgr-heading-xl">Είσοδος στην εφαρμογή</h1>
          <p className="govgr-body">
            Θα πρέπει να συνδεθείτε για να συνεχίσετε
          </p>

          {errorMessage ? (
            <div className="govgr-error-summary">
              <h3 className="govgr-heading-s">Υπήρξε κάποιο πρόβλημα</h3>
              <a className="govgr-link" href="#">
                {errorMessage}
              </a>
            </div>
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`govgr-field ${
                errors.username ? 'govgr-field__error' : ''
              }`}
            >
              <label className="govgr-label">
                <span>Όνομα Χρήστη</span>
                {errors.username?.type === 'pattern' ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span> Το
                    όνομα χρήστη πρέπει μόνο να περιλαμβάνει τα γράμματα a μέχρι
                    z, παπάκι και τελεία
                  </p>
                ) : errors.username ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span>{' '}
                    Εισάγετε όνομα χρήστη
                  </p>
                ) : null}
                <input
                  className={`govgr-input ${
                    errors.username ? 'govgr-error-input' : ''
                  }`}
                  type="text"
                  {...register('username', {
                    required: true,
                  })}
                />
              </label>
            </div>
            <div
              className={`govgr-field ${
                errors.password ? 'govgr-field__error' : ''
              }`}
            >
              <label className="govgr-label">
                <span>Κωδικός Πρόσβασης</span>
                {errors.password ? (
                  <p className="govgr-error-message">
                    <span className="govgr-visually-hidden">Λάθος:</span>{' '}
                    Εισάγετε κωδικός πρόσβασης
                  </p>
                ) : null}
                <input
                  className={`govgr-input ${
                    errors.password ? 'govgr-error-input' : ''
                  }`}
                  type="password"
                  {...register('password', {
                    required: true,
                  })}
                />
              </label>
            </div>
            <div className="mb-6">
              <Link className="govgr-link" to="/reset-password">
                Επαναφορά Κωδικού
              </Link>
            </div>
            <button
              className={`govgr-btn govgr-btn-primary govgr-btn-cta  ${
                !isValid ? 'govgr-btn-disabled' : ''
              }`}
              type="submit"
            >
              Σύνδεση
              <svg
                viewBox="0 0 24 24"
                className="govgr-arrow--right"
                focusable="false"
                aria-hidden="true"
              >
                {' '}
                <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" />{' '}
              </svg>
            </button>
          </form>
        </main>
      </div>
    </div>
  )
}

export default Login
