import axios from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import qs from 'qs'
import useAccessToken from 'store/Auth'
import AuthService from 'services/AuthService'
import api from 'api'

const token = useAccessToken.getState().accessToken

const LoginClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

// Login
export const createLogin = async (payload) => {
  const { data } = await LoginClient({
    method: 'post',
    url: '/app/govgr/analytics/users/login',
    data: {
      ...payload,
    },
  })
  return data
}

export const useLogin = (options = {}) => {
  return useMutation(createLogin, {
    ...options,
  })
}

// Logout
export const createLogout = async (payload) => {
  const { data } = await LoginClient({
    method: 'post',
    url: `/protocol/openid-connect/logout`,
    data: qs.stringify({
      client_id: 'govgr-analytics-web-client',
      refresh_token: AuthService.getRefreshToken(),
    }),
  })
}
export const useLougout = (options = {}) => {
  return useMutation(createLogout, {
    ...options,
  })
}
// Reset Password
export const createResetPassword = async (username, payload) => {
  const { data } = await api({
    method: 'get',
    url: `app/govgr/analytics/users/request-reset-password/${username}`,
  })
  return data
}

export const useResetPassword = (options = {}) => {
  return useMutation(createResetPassword, { ...options })
}

// Set new password
export const createSetNewPassword = async (payload) => {
  const { data } = await api({
    method: 'post',
    url: 'app/govgr/analytics/users/reset-password',
    data: { ...payload },
  })
  return data
}

export const useSetNewPassword = (options = {}) => {
  return useMutation(createSetNewPassword, { ...options })
}
