import create from 'zustand'
import { persist } from 'zustand/middleware'

const useToken = create<any>(
  persist(
    (set, get) => ({
      refreshToken: '',
      accessToken: '',
      setRefreshToken: (newToken) => set({ refreshToken: newToken }),
      setAccessToken: (newToken) => set({ accessToken: newToken }),
    }),
    {
      name: 'gov-auth',
    }
  )
)

export default useToken
