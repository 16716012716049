import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom'
import Login from './components/Login/index.tsx'
import Landing from './components/Landing/index.tsx'
import Statistics from './components/Statistics/index.tsx'
import Favorites from './components/Favorites/index.tsx'
import BasicLayout from './components/layouts/BasicLayout.tsx'
import Welcome from './components/Welcome/index.tsx'
import NotFoundPage from './components/NotFoundPage/index.tsx'
import { useMutation } from 'react-query'
import keycloak from 'keycloak'
import Keycloak from 'keycloak-js'
import { KeycloakAdapter } from 'keycloak-js'
import { useNavigate } from 'react-router-dom'
import RenderOnAuthenticated from 'components/Access/RenderOnAuthenticated'
import AuthService from 'services/AuthService'
import ResetPassword from 'components/ResetPassword'
import SetPassword from 'components/SetPassword'

function App() {
  const navigate = useNavigate()
  const [token, setToken] = React.useState('')
  const keycloakInit = useMutation(AuthService.initKeycloak)

  React.useEffect(() => {
    keycloakInit.mutate()
  }, [])

  setTimeout(function () {
    window.location.reload()
  }, 300000)

  return (
    <div className="App">
      {keycloakInit.isLoading ? (
        <>
          <div className="govgr-layout-wrapper govgr-layout-wrapper__full-height">
            <div className="govgr-full-page-background">
              <div className="govgr-loader__container">
                <svg
                  className="govgr-loader"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                >
                  <path
                    className="govgr-loader__circle govgr-loader__circle--1"
                    d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                  ></path>
                  <path
                    className="govgr-loader__circle govgr-loader__circle--2"
                    d="M26,99.551C26,55.478,60.246,27.5,99.85,27.5S174,55.478,174,99.551,139.455,172.5,99.85,172.5C60.273,172.473,26,143.6,26,99.551Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<BasicLayout />}>
            <Route index element={<Welcome />} />
            <Route
              path="login"
              element={
                AuthService.isLoggedIn() ? (
                  <Navigate to="/applications" replace />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route
              path="applications"
              element={
                <RenderOnAuthenticated>
                  <Landing />
                </RenderOnAuthenticated>
              }
            />
            <Route
              path="statistics/:applicationId"
              element={
                <RenderOnAuthenticated>
                  <Statistics />
                </RenderOnAuthenticated>
              }
            />
            <Route path="reset" element={<SetPassword />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      )}
    </div>
  )
}

export default App
