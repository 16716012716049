import React from 'react'
interface Props {
  active?: Boolean
  payload?: any
  label?: String | Number
}
function CustomTooltip({ active, payload, label }: Props) {
  if (active && payload) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Ημερομηνία : ${
          payload[0]?.payload?.day ?? 0
        }`}</p>
        <p className="label text-primary">{`Αιτήσεις : ${
          new Intl.NumberFormat('el-GR').format(payload[0]?.payload?.totals) ??
          0
        }`}</p>
        {payload[1]?.payload ? (
          <>
            <p className="label text-[#ff6500]">{`Αιτήσεις : ${
              new Intl.NumberFormat('el-GR').format(
                payload[1]?.payload?.totals
              ) ?? 0
            }`}</p>
          </>
        ) : null}
      </div>
    )
  }

  return null
}

export default CustomTooltip
