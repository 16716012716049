import * as React from 'react'
import { Link } from 'react-router-dom'

function Landing(props) {
  return (
    <>
      <div className="govgr-width-container flex items-center justify-center">
        <div className="govgr-main-wrapper">
          <main className="govgr-grid-column-two-thirds">
            <h1 className="govgr-heading-xl">
              Συγκέντρωση και παρουσίαση Δεδομένων ετερογενών εφαρμογών
            </h1>
            {/* <p className="govgr-body">
              A short introduction to explain things that most users will need
              to know
            </p> */}
            <Link to="/login">
              <button className="govgr-btn govgr-btn-primary govgr-btn-cta">
                Ξεκινήστε εδώ
                <svg
                  viewBox="0 0 24 24"
                  className="govgr-arrow--right"
                  focusable="false"
                  aria-hidden="true"
                >
                  {' '}
                  <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" />{' '}
                </svg>
              </button>
            </Link>
          </main>
        </div>
      </div>
    </>
  )
}

export default Landing
