import * as React from "react";

function NotFoundPage() {
  return (
    <div className="govgr-width-container flex items-center justify-center">
      <div className="govgr-main-wrapper">
        <main className="govgr-grid-column-two-thirds">
          <h1 className="govgr-heading-xl">Η σελίδα δεν βρέθηκε</h1>
          <p className="govgr-body">
            Εάν έχετε εισαγάγει τη διεύθυνση της σελίδας, ελέγξτε ότι είναι
            σωστή.{" "}
          </p>
          <p className="govgr-body">
            Εάν έχετε επικολλήσει τη διεύθυνση της σελίδας, ελέγξτε ότι έχετε
            αντιγράψει ολόκληρη τη διεύθυνση.
          </p>
          <p className="govgr-body">
            Εάν η διεύθυνση της σελίδας είναι σωστή ή έχετε επιλέξει έναν
            σύνδεσμο ή κουμπί, επικοινωνήστε με το γραφείο υποστήριξης σε
            περίπτωση που χρειάζεστε βοήθεια.
          </p>
        </main>
      </div>
    </div>
  );
}

export default NotFoundPage;
